import React,{useState,useEffect} from 'react'

export default function DiscoverKonzortiaCapital() {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check screen width
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Check the screen size on initial render and add event listener for resize
  useEffect(() => {
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <section className={` flex flex-col md:flex-row md:justify-center md:items-center h-auto py-10 gap-10 ${isMobile ? 'm-8' : 'ml-20'}`}>
          <div className='flex flex-col items-center w-full md:w-1/2'>
                
                    <video controls width="600" height="350">
                        <source src="/2minvideo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
          </div>
          <article className='flex flex-col items-start py-5 text-sm pb-8 w-full md:w-1/2'>
              <h2 className={` ${isMobile ? 'text-2xl' : 'text-3xl'} mb-4 text-left konzortia-blue-font`}>What is Konzortia Capital?</h2>
              <div className='max-w-[384px] text-left'>
                  <p className='konzortia-dark-blue-font'>
                    Konzortia Capital is a groundbreaking holding company at the forefront of revolutionizing Private Capital Markets through its subsidiary, Konzortia Hub. 
                    <br/>
                    Konzortia Hub, the flagship product of Konzortia Capital, stands as a catalyst for this transformative journey. Leveraging the power of AI, ML, and Distributed Ledger technologies, Konzortia Hub is reshaping the landscape of deal sourcing, evaluation, and execution processes within the realms of Venture Capital (VC), Private Equity (PE), and Mergers & Acquisitions (M&As).</p>
              </div>
          </article>
      </section>

    </>
  )
}
