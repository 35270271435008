import React,{useState,useEffect} from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OnePager() {
    const [fullName,setFullName]= useState('')
    const [email, setEmail]= useState('')
    const [phone, setPhone]= useState('')
    
    const openpager = ()=>{
        const url = 'https://docsend.com/view/s6ekws24vmqkw56f';
        window.open(url, '_blank');
    }
    const requestanalysis = (event)=>{
        // Prevent default form submission
        event.preventDefault();
        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            "email":email,
            "full_name":fullName,
            "phone":phone,
            'competitor':1
           })
        };
       
          try{ 
            fetch('/save.php', requestOptions)
            .then((response)=>{
              if(response.status===200){
                toast.success("Request sent!");
              }else{
                toast.error("An error occurred, Please check details and try again!");
              }
              }) 
            }catch(error){
              toast.error("An error occurred, Please check details and try again!");
            }
          
    }
    const [isMobile, setIsMobile] = useState(false);

    // Function to check screen width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    // Check the screen size on initial render and add event listener for resize
    useEffect(() => {
      handleResize(); // Check on initial render
      window.addEventListener('resize', handleResize); // Add event listener for window resize
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <>
        <section className='grid grid-cols-1 md:grid-cols-2'>
            <div className='flex flex-col items-center md:items-start mt-10'>
                <div className='grid grid-cols-1 md:grid-cols-2  mt-10'>
                    <div className='flex flex-col items-center'>
                        <p className='text-xl text-center md:text-left'>
                            Review our<br /><strong>Onepager</strong>
                        </p>
                        <button onClick={openpager} className={`Konzortia-BTN text-white p-2  px-10 ${isMobile ? 'm-auto' : ''} `}>Onepager</button>
                    </div>
                    <div className='flex flex-col items-center'><img className='w-80 mb-4' src="onepager.png" alt="" /></div>
                </div>
            </div>
            
            <div className='flex flex-col items-center col-span-1 grey-background'>
                <article className='flex flex-col items-center py-5 text-sm pb-8'>
                    <h3 className='text-xl mb-4'>Request Access to our <br/><strong>Competitive Analysis</strong></h3>
                    <form action='' className='w-full max-w-sm'>
                        <input value={fullName} onChange={(e)=> setFullName(e.target.value)} className='mb-4 w-full p-2' type="text" placeholder='Name' />
                        <input value={email} onChange={(e)=> setEmail(e.target.value)} className='mb-4 w-full p-2' type="email" placeholder='Email'/>
                        <input value={phone} onChange={(e)=> setPhone(e.target.value)} className='mb-4 w-full p-2' type="text" placeholder='Phone'/>
        
                        <button onClick={requestanalysis} className='Konzortia-BTN text-white p-2 mt-4 w-full'>Request</button>
                    </form>

                </article>
            </div>
        </section>
    </>
  )
}
