import React,{useState,useEffect} from 'react'

export default function MarketSolutions() {
    const [isMobile, setIsMobile] = useState(false);

    // Function to check screen width
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
  
    // Check the screen size on initial render and add event listener for resize
    useEffect(() => {
      handleResize(); // Check on initial render
      window.addEventListener('resize', handleResize); // Add event listener for window resize
  
      // Cleanup the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  return (
    <>
    <section className={`konzortia-blue h-auto pb-20 flex justify-center text-center flex-col `}>
        <h2 className={` text-3xl flex justify-center text-center text-lx py-10  konzortia-blue-font ${isMobile ? 'm-8' : 'ml-20'} `}>How are we different?</h2>
        <article className={`flex justify-center  ${isMobile ? 'm-8' : 'ml-20'}`}>
            <div className='max-w-[980px] text-center'>
                <p className='text-white text-center'>
                  At Konzortia Hub, AI is not just a buzzword—it's an essential component woven into the fabric of our business model. Unlike those who merely adopt AI as a trend, our model is designed with AI as a necessity, recognizing the pivotal role it plays in addressing the challenges of the Private Capital Markets.
                  <br/><br/>
                  In tackling the significant hurdles of this industry, Konzortia Hub presents a thoughtfully devised plan, introducing a set of features tailored to navigate the complexities across various verticals. Our approach is meticulous, addressing the specific needs of key players in the market. Key areas we focus on include:
                </p>
            </div>
        </article>
        <article className='max-w-[980px] grid grid-cols-1 md:grid-cols-4 gap-6 justify-center items-start mx-auto mt-20'>
            <article className='flex flex-col items-center w-full'>
                <img src="AI.png" alt="" className="mx-auto mb-4" />
                <h3 className='text-center text-l py-4 font-bold text-white'>Sourcing and Matching Deals (AI/ML)</h3>
                <p className='text-white text-center'>Leveraging advanced AI/ML algorithms to optimize deal sourcing and matching.</p>
            </article>
            <article className='flex flex-col items-center w-full'>
                <img src="db.png" alt="" className="mx-auto mb-4" />
                <h3 className='text-center text-l py-4 font-bold text-white'>Access to Real-Time Data and Benchmarking utilizing AI</h3>
                <p className='text-white text-center'>Providing access to reliable, real-time data and benchmarking tools for efficient deal evaluation through the power of AI.</p>
            </article>
            <article className='flex flex-col items-center w-full'>
                <img src="transaction.png" alt="" className="mx-auto mb-4" />
                <h3 className='text-center text-l py-4 font-bold text-white'>Secure and Traceable Transaction Execution</h3>
                <p className='text-white text-center'>Leveraging CUSIP-like codes, our blockchain technology ensures traceability and transparency throughout the transaction process.</p>
            </article>
            <article className='flex flex-col items-center w-full'>
                <img src="trade.png" alt="" className="mx-auto mb-4" />
                <h3 className='text-center text-l py-4 font-bold text-white'>Liquidity Access through Secondary Trading</h3>
                <p className='text-white text-center'>Facilitating access to liquidity and tradeable digital assets.</p>
            </article>
        </article>



    </section>

    </>
  )
}
