import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const teamMembers = [
  {
    image: 'walter.png',
    name: 'Walter Gomez',
    title: 'CEO, Co-Founder',
    description: 'Walter Gomez is the Founder & CEO of Konzortia Capital, leading the company since 2019. A self-taught entrepreneur, he previously grew a company to a successful exit and served as CEO of Sullivan Pratt Development Group in Costa Rica for 14 years. Walter excels in attracting talent and aims to revolutionize the financial sector through a Fintech Consortium. He holds a degree in Business Administration and Management from Universidad Fidélitas.'
  },
  {
    image: 'donnie.png',
    name: 'Donnie Gomez',
    title: 'COO, Co-Founder',
    description: 'Donnie Gomez, Co-Founder of Konzortia Capital, worked closely with his uncle on the management team at Sullivan Pratt. He holds a degree in banking and finance from Universidad Latina in Costa Rica. With extensive experience in strategy, finance, and business innovation, Donnie has led initiatives across Latin America. He spent over 7 years at Ernst & Young, specializing in governance, risk management, and global transfer pricing. Donnie focuses on banking, venture capital, insurance, public sector, services, and manufacturing. Currently, he is innovating in banking, capital markets, and investments.'
  },
  {
    image: 'tom.png',
    name: 'Thomas krutilek',
    title: 'Chief Marketing Officer',
    description: 'Tom Krutilek has over two decades of strategic marketing experience across various industries, including financial services, e-commerce, and entertainment. An expert in multi-channel marketing, he consistently achieves double-digit gains in leads, sales, and engagement. Tom excels in creating memorable digital and retail experiences, showcasing unique brand stories. His proven ability to improve marketing operations and mentor talent highlights his adaptability and commitment to peak performance.'
  },
  {
    image: 'aisha.png',
    name: 'Aisha Merhebi',
    title: 'Chief Technology Officer',
    description: 'Aisha El-Meherbi is the Team Lead and Senior Software Engineer at Konzortia Capital. As a Technology Manager for 6 years, she managed a team of engineers and QA, delivering multiple high-revenue products. With over 14 years of experience, Aisha has a strong technical background and a passion for technology. At Reuters, she was a Senior Software Engineer, creating new tech products with Agile methodologies. At Konzortia, Aisha focuses on Agile delivery, emphasizing security and quality.'
  },
  {
    image: 'anabel.png',
    name: 'Anabel Miquilena',
    title: 'Head Of Design',
    description: 'Anabel Miquilena is a System Engineer graduated from the Universidad Nacional Experimental Politécnica de la Fuerza Armada Bolivariana in Venezuela. Knowledgeable about IT, networking and programming, she’s also passionate about art and graphic design with proficiency in the use of tools, such as, Photoshop, Illustrator, Premiere Pro, HTML, WordPress, etc. This had led her to develop a successful carrier as freelancer and also in-house graphic designer and multimedia editor.'
  },
  {
    image: 'luis.png',
    name: 'Luis Brochet',
    title: 'Attorney',
    description: 'Luis is an experienced corporate lawyer with a strong academic background, holding an LL.M in International Business Law from Université Jean Moulin in Lyon, France, and a specialization in Corporate and Business Law from Universidad del Rosario in Colombia. He has extensive experience in corporate governance, regulatory compliance, M&A, and investment transactions. Luis provides strategic legal guidance and brings a comprehensive approach to meeting complex regulatory requirements, essential for achieving Konzortia’s goals.'
  }
];

export default function TeamSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <section className='konzortia-blue py-10'>
      <div className='container mx-auto px-4'>
        <h2 className='text-2xl text-white font-semibold text-center mb-6'>MANAGEMENT TEAM</h2>
        <div className='relative'>
          <Slider {...settings} ref={sliderRef}>
            {teamMembers.map((member, index) => (
             <div key={index} className='relative p-4 flex justify-center items-center'>
             <div className='relative w-[300px] h-[500px] bg-white bg-opacity-10 border border-white border-opacity-10 rounded-lg'>
               <img
                 src={member.image}
                 alt={member.name}
                 className='absolute top-[-3%] left-1/2 transform -translate-x-1/2 object-cover rounded-full'
               />
               <div className='text-center mt-20 p-10'>
                 <h3 className='text-l font-semibold text-white'>{member.name}</h3>
                 <h4 className='text-md font-medium konzortia-blue-font'>{member.title}</h4>
                 <p className='text-xs text-white mt-2'>{member.description}</p>
               </div>
             </div>
           </div>
            ))}
          </Slider>
          <button
            onClick={handlePrev}
            className='absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none'
          >
            ←
          </button>
          <button
            onClick={handleNext}
            className='absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none'
          >
            →
          </button>
        </div>
      </div>
    </section>
  );
}
