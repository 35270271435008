import React from 'react'

export default function Investment() {
  return (
    <>
        <section className='kon-lighterblue-bg text-white center-div flex justify-center text-center flex-col py-10 text-xl'>
        <img  src="investment.jpg" alt="" srcset="" />
        </section>
    </>
  )
}
