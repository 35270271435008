import React from 'react'

export default function () {
  return (
    <>
    <section className='bg-about px-10 py-10 flex justify-between items-center flex-col'>
        
          <img  src="About.png" alt="" srcset="" />
    </section>
    
    </>
  )
}
