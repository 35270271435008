import React,{useState,useEffect} from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RequestPitch() {
  const [fullName,setFullName]= useState('')
  const [email, setEmail]= useState('')
  const [phone, setPhone]= useState('')
  

  const calledBTN = ()=>{
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "email":email,
        "full_name":fullName,
        "phone":phone,
        "pitch":1
       })
    };
  
      try{ 
      fetch('/save.php', requestOptions)
      .then((response)=>{
        if(response.status===200){
          toast.success("Request sent!");
        }else{
          toast.error("An error occurred, Please check details and try again!");
        }
        }) 
      }catch(error){
        toast.error("An error occurred, Please check details and try again!");
      }

  }

  const [isMobile, setIsMobile] = useState(false);

  // Function to check screen width
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Check the screen size on initial render and add event listener for resize
  useEffect(() => {
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    < >
      <section className="konzortia-blue-image flex justify-center flex-col md:flex-row items-center pb-5 w-full">
        <article className={` ${isMobile ? '' : 'h-50 w-80 mx-36 pt-20 ml-auto md:ml-0 lg:ml-auto'} `}>
          {/* Add margin-bottom to the logo */}
          <div className="logo-header-container left-0 w-full flex justify-center mb-6">
            <img src="/kc_header.png" alt="Logo" className={`${isMobile ? '' : 'footer-logo'}`} />
          </div>

          {/* Add margin-top and margin-bottom to the title */}
          <h2 className="text-white text-center pitch-header text-2xl mb-6">
            Driving the Transformation of Private Capital Markets
          </h2>

          {/* Add margin-top to the form */}
          <article className={`grid w-80 text-white grid-cols-2 gap-6 mt-6 md:mt-0 ${isMobile ? 'm-auto' : ''}`}>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="bg-input col-span-2 h-10"
              type="text"
              placeholder="Name"
            />
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-input col-span-2 h-10"
              type="email"
              placeholder="Email"
            />
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="bg-input col-span-2 h-10"
              type="text"
              placeholder="Phone"
            />
          </article>

          <div className="flex justify-center mt-4">
            <button onClick={calledBTN} className="Konzortia-CTA-BTN text-white p-2 whitespace-nowrap">
              REQUEST INFORMATION
            </button>
              {/* Toast Container */}
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
 
          </div>
        </article>
      </section>

    
    </>
  )
}
