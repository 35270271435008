import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function BetaUsers() {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false, // Disable default arrows
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const clients = [
    "1.png", "2.png", "3.png", "4.png",
    "5.png", "6.png", "7.png", "8.png",
    "9.png", "10.png", "11.png"
  ];

  return (
    <section className='py-10'>
      <div className='container mx-auto px-4'>
        <h2 className='text-2xl font-semibold text-center mb-6'>Featured BETA Users</h2>
        <div className='relative'>
          <Slider {...settings} ref={sliderRef}>
            {clients.map((client, index) => (
              <div key={index} className='p-4'>
                <img src={client} alt={`Client ${index + 1}`} className='w-full h-full ' />
              </div>
            ))}
          </Slider>
          <button
            onClick={handlePrev}
            className='absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none'
          >
            ←
          </button>
          <button
            onClick={handleNext}
            className='absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none'
          >
            →
          </button>
        </div>
      </div>
    </section>
  );
}
