import './App.css';
import './index.css'
import './Components/RequestPitch'
import RequestPitch from './Components/RequestPitch';
import DiscoverKonzortiaCapital from './Components/DiscoverKonzortiaCapital';
import About from './Components/About';
import TakeAction from './Components/TakeAction';
import MarketSolutions from './Components/MarketSolutions';
import OnePager from './Components/OnePager';
import Investment from './Components/Investment';
import BetaUsers from './Components/BetaUsers';
import TeamSlider from './Components/TeamSlider';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <RequestPitch/>
      <DiscoverKonzortiaCapital/>
      <About/>
      <MarketSolutions/>
      <OnePager/>
      <Investment/>
      <BetaUsers/>
      <TeamSlider/>
      <TakeAction/>
      <Footer/>
    </>
  );
}

export default App;
